<template>
  <div>
    <div>
      <van-cell-group title="报废申请">
        <van-field v-model="user.NAME" readonly label="申请人" />
        <van-field v-model="user.DEPT_NAME" readonly label="部门" />
        <!-- <van-field label="申请日期" :value="model.date | datetime('YYYY-MM-DD')" @click="time.show = true"
          placeholder="请选择申请日期" is-link />
        <van-popup v-model="time.show" round position="bottom">
          <van-datetime-picker type="date" :min-date="time.minDate" v-model="time.curDate" @cancel="time.show = false"
            @confirm="timeConfirm" />
        </van-popup> -->
        <van-field v-model="model.THING_NAME" label="物品名称" required placeholder="请填写" />
        <van-field v-model="model.LOCATION" label="位置" placeholder="请填写" />
        <!-- <van-field v-model="model.CAT_NAME" label="物品编号" placeholder="请填写" /> -->
        <van-field v-model="model.CAUSE" label="报损原因" required placeholder="请填写" />
        <van-field v-model="model.COUNT" label="数量" required placeholder="请填写" />
        <van-field v-model="model.AMOUNT" label="金额" placeholder="请填写" />
      </van-cell-group>
      <van-cell-group title="上传图片">
        <van-uploader :after-read="afterRead" v-model="picList" @delete="model.IMAGES=''" max-count="3"
          style="margin: 10px 0px 0px 10px" />
      </van-cell-group>
      <van-cell-group title="备注">
        <van-field v-model="model.NOTE" type="textarea" rows="3" autosize show-word-limit maxlength="100"
          placeholder="请输入相关问题的详细说明" />
      </van-cell-group>

      <div style="margin: 15px">
        <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
          loading-text="正在提交。。。">确认提交</van-button>
        <br />
        <van-button plain type="default" size="large" icon="cross" block @click="back">取消返回</van-button>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
  import moment from "moment/moment";

  export default {
    data() {
      return {
        model: {
          // date: new Date(),
        },
        user: this.whale.user.get(),
        cat: {
          show: false,
          ls: []
        },
        loc: {
          show: false,
          ls: []
        },
        picList: [],
        ret: {
          loading: false
        },
        time: {
          show: false,
          minDate: moment().add(-1, 'y').toDate(),
          curDate: moment().toDate(),
        },
      }
    },
    created() {

    },
    methods: {
      timeConfirm(val) {
        this.time.show = false;
        this.model.date = val;
      },
      afterRead(f) {
        let self = this;
        this.whale.image.compress({
          file: f.file,
          callback(f) {
            self.whale.image.toB64({
              file: f,
              callback(b64) { self.model.IMAGES = b64 }
            })
          }
        })
      },
      submit() {
        console.log(this.model)

        let self = this;
        self.ret.loading = true;

        this.whale.remote.getResult({
          url: "/api/Mobile/LOSS/RecordApi/Create",
          data: this.model,
          finally() {
            self.ret.loading = false;
          },
          completed() {
            self.$dialog.confirm({
              title: "完成",
              message: "报损记录已成功提交，请等待处理！",
              theme: "round",
              confirmButtonText: "继续录入",
              cancelButtonText: "返回"
            }).then(() => {
              self.$router.go(0);
            }).catch(() => {
              self.$router.push("/scrap/index")
            })
          }
        })
      },
      back() {
        this.$router.go(-1)
      }
    }
  }
</script>
<style scoped></style>